#footer {
  background-color: #f5f7f9;
  min-height: 200px;
  text-align: center;
}

#footer .footer-content {
  padding-top: 50px;
}

#footer img {
  max-width: 500px;
}

#header {
  background: #fff;
  z-index: 997;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  min-height: 100px;
}

#header img {
  max-width: 350px;
}

@media (max-width: 992px) {
  #footer img {
    max-width: 100%;
  }

  #header img {
    max-width: 250px;
  }
}

@media (max-width: 640px) {
  #header img {
    max-width: 200px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */

.nav-menu a {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  color: black;
  transition: 0.3s;
  font-size: 14px;
  font-family: "Graphik";
  padding: 5px 2px;
  border-bottom: 2px solid #fff;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  color: #377e29;
  border-color: #377e29;
}

.breadcrumb {
  border: 1px dashed;
  background-color: white;
  text-transform: uppercase;
  padding: 0px 5px;
  margin: 0px 5px;
}
