#homeosiniatria div {
  text-align: justify;
  text-justify: inter-word;
  padding: 1ex;
}

#homeosiniatria img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  height: auto;
}

.smallImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 10%;
}

#homeosiniatria figcaption {
  text-align: center;
  font-weight: 600;
  margin: 1%;
}

#homeosiniatria table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-left: auto;
  margin-right: auto;
}



#homeosiniatria .italica{
  font-style: italic;
  border-color: #ffffff;
  border-style: solid;
  font-style: italic;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  word-break: normal;
}
#homeosiniatria .noBorders{
  border-color: #ffffff;
  border-style: solid;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  word-break: normal;
  
}
#homeosiniatria .borderAndColor td  {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  word-break: normal;
  padding: 10px;
}

#homeosiniatria .borderAndColor th  {
  border-color: black;
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  word-break: normal;
}
#homeosiniatria .center {
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#homeosiniatria .tripleColumn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#homeosiniatria .doubleColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


#homeosiniatria .sticky-top-menu {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99999999999;
  background-color: #fff;
  padding: 2px 0px;
  margin-left: 10px;
  max-height: 6vh;
}