#servicios {
  background: #f0f9f3;
  padding: 70px 0 60px;
  margin-top: 200px;
}

#servicios .icon-box {
  text-align: center;
  border-radius: 30px;
  border: 2px solid #000;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  min-height: 75vh;
}

#servicios .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #63c087;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

#servicios .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #9fd8b5;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

#servicios .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #63c087;
}

#servicios .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

#servicios .icon-box:hover {
  background: #63c087;
  border-color: #63c087;
}

#servicios .icon-box:hover .icon {
  background: #fff;
}

#servicios .icon-box:hover .icon::before {
  background: #3d975f;
}

#servicios .icon-box:hover h4,
#servicios .icon-box:hover p {
  color: #fff;
}

@media (max-width: 992px) {
  #servicios {
    margin-top: 0px;
  }

  #servicios .icon-box {
    margin: 30px 0px;
  }

  #servicios .icon-box {
    min-height: 50vh;
  }
}

#servicios .responsive-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 20px;
}

#servicios .responsive-video iframe {
  margin-left: 10%;
  height: 80%;
  width: 80%;
  position: absolute;
}
