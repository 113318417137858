#datos .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #63c087;
  border-radius: 30px;
}

#datos .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #fff;
}

#datos .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Graphik";
  font-size: 14px;
  color: #fff;
}

#datos .count-box {
  margin: 30px 0px;
}
