.book-container {
  left: auto;
  right: auto;
  margin-left: 10vw;
  margin-right: 10vw;
}

#enfermedades .book-container {
  text-align: center;

  left: auto;
  right: auto;
  margin-left: 10vw;
  margin-right: 10vw;
}

.chap-container {
  margin-top: 30vh;
  text-align: justify;
  white-space: pre-line;
}

.chap-container h2 {
  text-align: center;
}

.chap-container h2 + img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.sub-chap-container {
  margin-top: 10vh;
}

.book-container .list-group .list-group-item {
  border: none;
  text-align: left;
}

.book-container .list-group .list-group-item h3 {
  margin-left: 25%;
}

.chap-list-container {
  overflow-y: scroll;
  height: 90vh;
}

.sticky-top-menu {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99999999999;
  background-color: #fff;
  padding: 5px 0px;
  max-height: 6vh;
}

#sidebar-wrapper {
  width: auto;
  margin-left: -1rem;
  background: #63c087;
  color: #fff;
  height: 100vh;
  overflow-y: scroll;
  position: sticky;
  position: -webkit-sticky;
  top: 0vh;
}

#enfermedades {
  white-space: pre-line;
}

#enfermedades li {
  text-align: left;
}
#atlas-container #sidebar-wrapper {
  width: auto;
  margin-left: -1rem;
  background: #63c087;
  color: #fff;
  height: 100vh;
  overflow-y: scroll;
  position: sticky;
  position: -webkit-sticky;
  top: 5vh;
}

#sidebar a {
  max-width: 100%;
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 10px;
}

#sidebar .capitulo {
  border: none;
  background: #63c087;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  width: 100%;
}

#sidebar div > a {
  word-wrap: break-word;
  white-space: normal;
}

#sidebar .dropdown-menu > a {
  word-wrap: break-word;
  white-space: normal;
}

#sidebar .dropdown-item:hover {
  background: #63c087;
}

#sidebar .dropdown-item {
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
}

#sidebar .nav-item {
  background: #63c087;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  width: 100%;
}

#sidebar .dropdown-menu.show {
  background: #63c087;
  color: black;
  font-size: 14px;
  display: block;
  width: 100%;
}

#sidebar .dropdown-menu.show a {
  color: black;
}

#sidebar a:hover {
  color: #fff;
}
#sidebar ul li.active > a {
  background: transparent;
  color: #fff;
}

#bookmarks button span:hover {
  background-color: rgba(211, 211, 211, 0.5);
}

.madera {
  color: green;
}

.fuego {
  color: red;
}

.agua {
  color: blue;
}

.metal {
  color: #c3cace;
}

.tierra {
  color: orange;
}

.madera h3 a {
  color: green;
}

.fuego h3 a {
  color: red;
}

.agua h3 a {
  color: blue;
}

.metal h3 a {
  color: #c3cace;
}

.tierra h3 a {
  color: orange;
}

.na h3 a {
  color: black;
}

.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .book-container .list-group .list-group-item h3 {
    margin-left: 0vw;
  }

  .book-container {
    left: auto;
    right: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  #sidebar a {
    font-size: 14px;
    padding: 15px 0;
  }

  #sidebar .dropdown-menu.show {
    font-size: 12px;
  }
}
@media (max-width: 712px) {
  .book-container {
    left: auto;
    right: auto;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  #sidebar-wrapper {
    height: 10vh;
    z-index: 20;
  }
}

@media print {
  .book-container {
    display: none;
  }
}
