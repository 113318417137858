/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 90vh;
  background: url('../assets/img/Home_Aurea.jpg') top center;
  background-size: cover;
  position: relative;
  margin-bottom: -200px;
}

#hero #Aurea {
  display: inline-block;
  padding: 12px 35px;
  margin-top: 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
}

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #63c087;
}

#hero h2 {
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: 'Graphik';
  text-transform: uppercase;
  font-weight: 2000;
  font-size: 20px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 35px;
  margin-top: 14vh;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: rgba(99, 192, 135, 0.9);
}

#hero .btn-get-started-2 {
  font-family: 'Graphik';
  text-transform: uppercase;
  font-weight: 2000;
  font-size: 20px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 35px;
  margin-top: 3vh;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: rgba(99, 192, 135, 0.9);
}

#hero .btn-get-started:hover {
  background: #3d975f;
}

#hero .btn-get-started-2:hover {
  background: #3d975f;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 992px) {
  #hero {
    margin-bottom: 0;
    height: 150vh;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: auto;
  }
}

#hero .content {
  margin-top: 50px;
  padding: 30px;
  background: rgba(99, 192, 135, 0.9);
  border-radius: 30px;
  color: #fff;
}

#hero .content h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

#hero .content p {
  margin-bottom: 30px;
}

#hero .content .more-btn {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  padding: 6px 30px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

#hero .content .more-btn:hover {
  color: #fff;
  background: #3d975f;
}

#hero .content-small {
  padding: 30px;
  margin-top: 90px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
}

#hero .content-small h4 {
  color: #63c087;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}

#hero .content-small p {
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  #hero .content {
    margin: 30px 0px;
  }
  #hero .btn-get-started {
    margin-top: 15px;
  }

  #hero .content-small {
    margin-top: 15px;
  }

  #hero .content {
    margin-top: 30px;
  }

  #hero .btn-get-started {
    margin-top: 45px;
  }
}
