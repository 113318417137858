.scroll-menu-medicamento {
  overflow-y: scroll;
  height: 85vh;
}

@media (max-width: 640px) {
  .scroll-menu-medicamento {
    height: 20vh;
  }
}

.scroll-medicamento {
  overflow-y: scroll;
  height: 90vh;
}
