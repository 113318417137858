/* Card Payment Section - Start */
.payment-form {
  padding-bottom: 10px;
  margin-right: 15px;
  margin-left: 15px;
}

.payment-form.dark {
  background-color: #f6f6f6;
}

.payment-form .content {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: white;
}

.payment-form .block-heading {
  padding-top: 40px;
  margin-bottom: 30px;
  text-align: center;
}

.payment-form .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  color: RGBA(0, 0, 0, 0.45);
}

.payment-form .block-heading h1,
.payment-form .block-heading h2,
.payment-form .block-heading h3 {
  margin-bottom: 1.2rem;
  color: #63c087;
}

.payment-form .form-payment {
  border-top: 2px solid #c6e9fa;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: #ffffff;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

.payment-form .title {
  font-size: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8em;
  font-weight: 400;
  padding-bottom: 8px;
}

.payment-form .payment-details {
  padding: 25px 25px 15px;
  height: 100%;
}

.payment-form .payment-details label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #8c8c8c;
  text-transform: uppercase;
}

.payment-form .payment-details button {
  margin-top: 0.6em;
  padding: 12px 0;
  font-weight: 500;
  background-color: #63c087;
  margin-bottom: 10px;
  border: none;
}

.payment-form .payment-details button:hover {
  background-color: #3d975f;
}

.payment-form .date-separator {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.payment-form a,
.payment-form a:not([href]) {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #63c087;
  cursor: pointer;
}

.payment-form a:not([href]):hover {
  color: #3d975f;
  cursor: pointer;
}

.input-background {
  background-position: 98% 50%;
  background-repeat: no-repeat;
  background-color: #fff;
}

#horizontal_logo {
  width: 150px;
  margin: 0;
}
.bouncer-container {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  max-width: 600px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.bouncer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bouncer-container h2 {
  margin-top: -40px;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #63c087;
}

.bouncer-container p {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.bouncer-container p span {
  font-weight: bold;
}

.bouncer div {
  width: 75px;
  height: 75px;
  background-color: #63c087;
  border-radius: 50%;
  animation: bouncer 0.5s cubic-bezier(0.19, 0.57, 0.3, 0.98) infinite alternate;
}

.bouncer div:nth-child(2) {
  animation-delay: 0.1s;
  opacity: 0.8;
}

.bouncer div:nth-child(3) {
  animation-delay: 0.2s;
  opacity: 0.6;
}

.bouncer div:nth-child(4) {
  animation-delay: 0.3s;
  opacity: 0.4;
}

.payment-wrapper {
  text-align: center;
}

@keyframes bouncer {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100px);
  }
}

@media (max-width: 500px) {
  .payment-form .form-payment {
    border-top: 2px solid #c6e9fa;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
    background-color: #ffffff;
    padding: 0;
    max-width: 600px;
    margin: auto;
    position: relative;
  }
  .bouncer-container {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .bouncer div {
    width: 35px;
    height: 35px;
    margin-bottom: -50px;
    background-color: #63c087;
    border-radius: 50%;
    animation: bouncer 0.5s cubic-bezier(0.19, 0.57, 0.3, 0.98) infinite
      alternate;
  }
}

@media (min-width: 576px) {
  .payment-form .title {
    font-size: 1.2em;
  }

  .payment-form .products {
    padding: 40px;
  }

  .payment-form .products .item-name {
    font-size: 1em;
  }

  .payment-form .products .price {
    font-size: 1em;
  }

  .payment-form .payment-details {
    padding: 40px 40px 30px;
  }

  .payment-form .payment-details button {
    margin-top: 1em;
    margin-bottom: 15px;
  }
}
