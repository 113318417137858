.dot {
  height: 25px;
  width: 25px;

  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.dot-red {
  background-color: red;
  color: white;
}
.dot-black {
  background-color: black;
  color: white;
}
.dot-blue {
  background-color: blue;
  color: white;
}
.dot-neutro {
  background-color: #bbb;
}

.scrollableTable {
  overflow-x: scroll;
  max-width: 100%;
}
