/* For all pages */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  text-align: center;
}

.list-group-item {
  min-height: 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-link-style {
  color: #000;
}
main {
  padding-top: 15px;
}

#watermark {
  width: 100%;

  background-size: cover;
  position: relative;
}

#watermark::after {
  content: "";
  background: url("../assets/img/LogoCrop.png");
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.repertorio-3 {
  color: red;
  text-transform: uppercase;
  cursor: pointer;
}
.repertorio-2 {
  color: blue;
  text-transform: lowercase;
  font-style: italic;
  cursor: pointer;
}
.repertorio-1 {
  color: black;
  text-transform: lowercase;
  cursor: pointer;
}

.repertorio-titulo {
  color: black;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}

.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #2b2eff;
  color: #2b2eff;
  cursor: pointer;
}

hr {
  border-top: 1px dashed;
}

.my-container {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.scroll-menu-medicamento .active {
  color: #377e29;
  border-color: #377e29;
  font-weight: bold;
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.building-content {
  padding: 300px 0px;
  text-align: center;
  font-family: "Graphik";
}

.scrollable {
  max-height: 300px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.error {
  color: red;
}

.error-small {
  color: red;
  font-size: 12px;
}

.form-error {
  border: 2px solid red;
  border-radius: 4px;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: normal;
  src: local("Tangerine"),
    url("../assets/graphik/Graphik-Regular.otf") format("truetype");
}

body {
  font-family: "Graphik";
}

a {
  color: #63c087;
}

a:hover {
  color: #3d975f;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Graphik";
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f1f7fd;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  text-transform: uppercase;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #63c087;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #63c087;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  filter: invert(100%);
}

#planes .btn-get-started:hover {
  background: #3d975f;
}

#planes .btn-get-started {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 35px;
  margin-top: 30px;
  margin-bottom: 15px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: rgba(99, 192, 135, 0.9);
}

#planes .card-title {
  padding: 24px 35px;
}

#planes .descripcion-plan {
  margin: 0px 15px;
  text-align: justify;
  text-justify: inter-word;
}

#planes .footer {
  padding: 10px;
}

#planes .precio {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  font-weight: bold;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #63c087;
  border-radius: 10px;
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #377e29;
}

@media print {
  button {
    display: none;
  }
}
